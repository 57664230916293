import axios from 'axios';

const baseURL = new URL('/v1/users', import.meta.env.VITE_API_BASE_URL);
export const fetchUser = axios.create({
  baseURL: baseURL.toString(),
  addCurrentInstitution: true,
});

const dsBaseUrl = new URL(
  '/v1/digital-skills/accounts',
  import.meta.env.VITE_API_BASE_URL,
);
export const dsFetchUser = axios.create({
  baseURL: dsBaseUrl.toString(),
  addCurrentInstitution: true,
});
